import { useState } from 'react';
import './KeyProjects.css';
import x from '../static/assets/images/x-symbol.png';
import { Link } from 'react-router-dom';
import keyProjects from '../static/data/keyprojects.json';

function KeyProjects() {
   const elementStyleArray = Array(100).fill(true);
   const [elementStyle, setElementStyle] = useState(elementStyleArray);

   function setElement(id: number, state: boolean) {
      const updatedArray = [...elementStyle];
      updatedArray[id] = state;
      setElementStyle(updatedArray);
   }

   return (
      <section>
         <div className="bg-primary-gradient keyprojects-container ">
            <div className="title pt-5">
               {' '}
               {/* video */}
               <h1>key projects</h1>
            </div>

            <div className='p-5'>

            {keyProjects.map((project, index) => (
               <div className={`expand-container ${index % 2 === 0 ? 'row' : 'row-reverse'}`} key={index}>
                  <div className="big">
                     <div onClick={() => setElement(index, !elementStyle[index])}>
                        <h2>{project.name}</h2>
                        <button className={elementStyle[index] ? '' : 'rotate'}>
                           <img src={x} alt='x' width="30px" />
                        </button>
                     </div>
                     <div className={elementStyle[index] ? 'expand' : ''}>
                        <p>{project.intro}</p>
                     </div>
                     <div className={elementStyle[index] ? '' : 'c'}>
                        <p>{project.description}</p>
                        <div className="kp-button-container">
                           <Link className="btn kp" to={project.url} target="_blank">
                              learn more
                           </Link>
                        </div>
                     </div>
                  </div>
                  
                  <div>
                     <Link to={project.url} target="_blank">
                        <img src={project.imageLg} width={project.imageLgWidth} alt={project.name} className={`kp-img ${elementStyle[index] ? '' : 'open'}`} />
                     </Link>
                  </div>
               </div>
            ))}

            </div>

            
         </div>
      </section>
   );
}

export default KeyProjects;
