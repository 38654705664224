import './Card.css';
import { PieChart as RechartsPieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import "./AboutPie.css";

const data = [
    { name: 'sol nft projects', value: 21 },
    { name: 'early contributors', value: 21 },
    { name: 'market participants and defi users', value: 15.8 },
    { name: 'bonk dao', value: 15.8 },
    { name: 'artists & collectors', value: 10.5 },
    { name: 'solana devs', value: 5.3 },
    { name: 'initial liquidity', value: 5.3 },
    { name: 'marketing', value: 5.3 },
  ];
  
  const COLORS = ['#ff0000', '#ff0000', '#ff3232', '#ff3232', '#ff7f7f', '#ff7f7f', '#ff7f7f', '#ff7f7f'];

export function PieChart(
) {
   return (
    <div className="pie-container">
        <RechartsPieChart width={500} height={530}>
        <Pie
        data={data}
        cx={250}
        cy={190}
        outerRadius={200}
        fill="#8884d8"
        dataKey="value"
        nameKey="name"
        label={(entry) => `${entry.value}%`}
        >
        {data.map((entry, index) => (
            <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]} />
        ))}
        </Pie>
        <Tooltip />
        <Legend
        payload={data.map((entry, index) => ({
        value: entry.name,
        type: 'circle',
        color: COLORS[index % COLORS.length], }))} />
        </RechartsPieChart>
    </div>
   );
}
