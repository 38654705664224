import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import BonkLogo from '../static/assets/images/bonkog_200.png';
import HamburgerMobile from './Hamburger';
import useIsMobile from '../utils/mobile';

function Header() {
   const isMobile = useIsMobile();
   return (
      <>
         {isMobile ? (
            <div className='flex between gap items-center p-2'>
               <div className=''><Link to="/"><img src={BonkLogo} height="40px" /></Link></div>
               <div><HamburgerMobile /></div>
            </div>
         ) : (
            <header className="bg-primary-gradient flex between items-center">
               <Link to="/">
                  <img src={BonkLogo} />
               </Link>
               <div className="header-bar flex gap-large">
                  <div>
                     <Link className="hover" to="/">
                        Home
                     </Link>
                  </div>
                  <div>
                     <Link className="hover" to="/keyprojects">
                        KEY PROJECTS
                     </Link>
                  </div>
                  <div>
                     <Link className="hover" to="/integrations">
                        INTEGRATIONS
                     </Link>
                  </div>
                  
                  <div>
                     <Link className="hover" to="/bonkart">
                        ART
                     </Link>
                  </div>
                  {/* <div>
                     <Link className="hover" to="/nft">
                        NFT
                     </Link>
                  </div> */}
                  <div>
                     <Link className="hover" to="/about">
                        ABOUT
                     </Link>
                  </div>
               </div>
            </header>
         )}
      </>
   );
}

export default Header;
