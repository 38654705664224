import { fetchBonkDataBonkart as fetchBonkDataBonkArt } from '../services/strapi.service';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArtworkApiResponseModel } from '../models/artworks-api-response.modal';
import '../pages/BonkArt.css';
import '../static/style/loading.css';
import artlogo from '../static/assets/images/bonkart.png';
import { Loading } from '../components/Loading';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import telegram from '../static/assets/images/telegram.png';
import x from '../static/assets/images/xlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

function BonkArt() {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<ArtworkApiResponseModel | undefined>(undefined);

   async function fetchData() {
      const responseData = await fetchBonkDataBonkArt();
      if (responseData) {
         responseData.data.sort(() => Math.random() - 0.5); //shuffle the array
         setData(responseData || undefined);
      }
      setLoading(false);
   }

   useEffect(() => {
      fetchData();
   }, []);

   return loading ? (
      <Loading />
   ) : (
      <section>
         <div className="bg-primary-gradient bonkart p-5">
            <div className="ba-top">
               <div>
                  <img src={artlogo} width="100px" height="100px" />
                  <h1>
                     bonk
                     <br />
                     art
                     <br />
                     master
                  </h1>
               </div>
               <div>
                  <p>BONK Art Master features our iconic BONK shiba inu through the eyes of talented artists on-chain. Each artist interpretation is unique. Collectable pieces loved by the BONK community, made for the BONK community.</p>
               </div>
            </div>
         </div>

         <div className="bg-primary-gradient p-5">
            <Swiper
               slidesPerView={1}
               pagination={{
                  clickable: true,
               }}
               navigation={true}
               loop
               autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
               }}
               modules={[Pagination, Navigation, Autoplay]}
               className="mySwiper">
               {data?.data.map((item, index) => {
                  return (
                     <SwiperSlide key={index}>
                        <Link to={item.attributes.url} target="_blank">
                           <div className="bonkart-swipe">
                              {/* <div className="bonkart-swiper-image" style={{ backgroundImage: `url('${item.attributes.imageUrl}')` }}></div> */}
                              <img src={item.attributes.imageUrl} alt="" />
                              <div className="bonkart-swiper-description flex column gap-small">
                                 <h2>{item.attributes.title} <FontAwesomeIcon icon={faCircleInfo} title={item.attributes.description} /></h2>

                                 <div className='flex gap-small items-center'>
                                    
                                    {item.attributes.x && <a href={`https://twitter.com/${item.attributes.x}`}><img src={x} height="20px" /></a>}
                                    {item.attributes.telegram && <a href={`https://t.me/${item.attributes.telegram}`}><img src={telegram} width="20px" /></a>}
                                    <span className='text-art-sm'>{item.attributes.name} <FontAwesomeIcon icon={faCircleInfo} title={item.attributes.bio} /> </span>

                                 </div>
                                    
                                    
                                    
                                 
                                 </div>
                           </div>
                           
                        </Link>
                     </SwiperSlide>
                  );
               })}
            </Swiper>
         </div>

       
      </section>
   );
}

export default BonkArt;
