import './Social.css';
import { Link } from 'react-router-dom';
import logo from '../static/assets/images/bonkog_800.png';
import discord from '../static/assets/images/discord.png';
import threads from '../static/assets/images/threadslogo.png';
import medium from '../static/assets/images/medium.png';
import youtube from '../static/assets/images/youtube.png';
import instagram from '../static/assets/images/instagram.png';
import telegram from '../static/assets/images/telegram.png';
import x from '../static/assets/images/xlogo.png';
function Social() {
    return (

        <section className="social bg-orange-secondary-gradient top-border">
            <div>
                <img src={logo} width="250px" alt="logo" />

                <div>
                    <Link to="https://twitter.com/bonk_inu">
                        <img src={x} width="50px" alt="logo" />
                    </Link>
                    <Link to="https://discord.com/invite/qaQa6M6mN2">
                        <img src={discord} width="50px" alt="logo" />
                    </Link>
                    {/* <Link to="https://www.threads.net/@official_bonk_inu">
                        <img src={threads} width="50px" alt="logo" />
                    </Link> */}
                    <Link to="https://medium.com/@bonk_inu">
                        <img src={medium} width="50px" alt="logo" />
                    </Link> 
                    <Link to="https://www.youtube.com/@bonk_inu">
                        <img src={youtube} width="50px" alt="logo" />
                    </Link>
                    <Link to="https://www.instagram.com/official_bonk_inu/">
                        <img src={instagram} width="50px" alt="logo" />
                    </Link>
                    <Link to="https://t.me/Official_Bonk_Inu">
                        <img src={telegram} width="50px" alt="logo" />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Social;


