import { Link, useNavigate } from 'react-router-dom';
import "./Hamburger.css";
import { useState } from 'react';
import x from "../static/assets/images/x-symbol.png";
import dot from "../static/assets/images/arrow_bar.png";
function HamburgerMobile() {
   const navigate = useNavigate();

   const [isOpen, setIsOpen] = useState(false);


   return (
      <div className="donation-header">
         <div className="hamburger">
            <button onClick={() => setIsOpen(true)} />
            <div className={isOpen ? "" : "close"}>
               <div className="column">
                  <div>
                     <button onClick={() => setIsOpen(false)}>
                        <img width="30px" height="30px" src={x} alt="x" />
                     </button>
                  </div>
                  <div className="elements">
                     <button onClick={() => { setIsOpen(false); navigate('/'); }}>
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>HOME</p>
                     </button>
                     <button onClick={() => { setIsOpen(false); navigate('/keyprojects'); }} >
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>KEY PROJECTS</p>
                     </button>
                     <button onClick={() => { setIsOpen(false); navigate('/integrations'); }}>
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>INTEGRATIONS</p>
                     </button>
                     <button onClick={() => { setIsOpen(false); navigate('/bonkart'); }} >
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>ART</p>
                     </button>
                     <button onClick={() => { setIsOpen(false); navigate('/nft'); }} >
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>NFT</p>
                     </button>
                     <button onClick={() => { setIsOpen(false); navigate('/about'); }}>
                        <img width="15px" height="15px" src={dot} alt="dot" />
                        <p>ABOUT</p>
                     </button>
                     
                     
                  </div>
               </div>
            </div>
            <div className={isOpen ? "" : "close"}>
               <button onClick={() => setIsOpen(false)} />
            </div>
         </div>
      </div>
   );
}

export default HamburgerMobile;
