import { useContext, useEffect, useState } from "react";
import { DataContext } from "../contexts/data.context";
import { useLocation } from "react-router-dom";

export function useIntegrations () {
    const [loading, setLoading] = useState<boolean>(false);
   const [category, setCategory] = useState<string>();
   const [featuredIntegrations, setFeaturedIntegrations] = useState<any>();
   const [additionalIntegrations, setAdditionalIntegrations] = useState<any>();
   const { categories } = useContext(DataContext);

   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const categoryQueryParam = queryParams.get('category');

   useEffect(() => {
      setLoading(true);
      setCategory(categoryQueryParam || 'Featured');
      setLoading(false);
   }, [categoryQueryParam]);

   useEffect(() => {
      setFeaturedIntegrations(
         categories
            ?.find((categories) => categories.attributes.name === category)
            ?.attributes.integrations.data.sort((a, b) => a.attributes.sort - b.attributes.sort)
            .filter((integration) => integration.attributes.featured === true && integration.attributes.active === true)
      );
      setAdditionalIntegrations(
         categories
            ?.find((categories) => categories.attributes.name === category)
            ?.attributes.integrations.data.sort((a, b) => a.attributes.sort - b.attributes.sort)
            .filter((integration) => integration.attributes.featured === false && integration.attributes.active === true)
      );
   }, [category, categories]);

    return { loading, category, categories,  setCategory, featuredIntegrations, additionalIntegrations };
}