import { useNavigate } from 'react-router-dom';
import Earth from '../static/assets/images/fixed_earth.png';
import chains from '../static/data/chains.json';
import './Chains.css';

function Chains() {
   const navigate = useNavigate();

   return (
      <>
         <section>
         <div className="container">
            <div className="title">
               {' '}
               <h1>chains</h1>
            </div>
            </div>
    
            <div className="integrations-container container">
               <div className="chains-grid">
               {chains &&
                  chains.map((chain, index) => (
                     <a target='_blank' href={chain.url}>
                        <div className="chains-card">
                           <div key={index}>
                              <h2><a target='_blank' href={chain.url}>{chain.name}</a></h2>
                              {/* <p>{chain.description}</p> */}
                           </div>
                        </div>
                     </a>
                  ))}
            </div>
                  </div>
         </section>

         <section>
            <div className="bottom">
               <h1>With over 100+ integrations and counting, BONK has asserted its place as one of the premier community coins in web3.</h1>
               <img src={Earth} alt="earth" />
            </div>
         </section>
      </>
   );
}

export default Chains;
