import { CategoryApiResponseModel } from "../models/category-api-response.model";

export function getNumIntegrations(data: CategoryApiResponseModel): number { //get num integrations
    return data.data.reduce((sum: any, categories: any) => sum + (categories.attributes.integrations.data.length || 0), 0);
}

export function getUseBonk(data: any): Array<[string, number]> { //get usebonk data
    let useBonkResultList: Array<[string, number]> = [];
    data && data.data.map((category: any) => data && data.data
       .filter((integration: any) => category.attributes.name === integration.attributes.name)
       .map((integration: any) => {
          const featuredCount = integration.attributes.integrations.data.length;
          useBonkResultList.push([integration.attributes.name, featuredCount]);
       })
    );
    useBonkResultList.sort((a, b) => b[1] - a[1]); //sort results
    
    return useBonkResultList;
}

export function getGetBonk(data: any): Array<[string, string, string]> { //get getbonk data
    let getBonkResultList: Array<[string, string, string]> = [];
    data && data.data
       .filter((categories: any) => categories.attributes.name === 'Exchange')
       .map((categories: any) => data && data.data
          .filter((integrations: any) => integrations.attributes.name === categories.attributes.name)
          .map((integration: any) => integration.attributes.integrations.data
             .filter((integrations: any) => integrations.attributes.featured === true)
             .sort((a: any, b: any) => a.attributes.sort - b.attributes.sort)
             .map((featured: any) => {
                return getBonkResultList.push([featured.attributes.name, featured.attributes.imageUrl, featured.attributes.url]);
             })
          )
       );
    return getBonkResultList;
}