import { useState } from 'react';
import './Privacy.css';

function Privacy() {
   return (
      <section>
         <div className="bg-primary-gradient p-5 privacy-content">

            <h1 className='header'>Privacy Policy</h1>

            <p><strong>Last updated:</strong> October 22, 2024</p>

            <h2 className='header2'>1. Introduction</h2>
            <p>Welcome to Bonkcoin.com ("Site," "we," "our," or "us"). This privacy policy explains how we handle information when you visit our website. We are committed to privacy and maintain minimal data collection.</p>

            <h2 className='header2'>2. Our Data Practices</h2>

            <h3>2.1 Information We Collect</h3>
            <p>We only collect:</p>
            <ul>
               <li>Anonymous page visit counts</li>
               <li>Total website traffic statistics</li>
            </ul>
            <p>This anonymous data cannot be used to identify individual visitors and only provides aggregate numbers of website visits.</p>

            <h3>2.2 Information We Do Not Collect</h3>
            <p>We do not collect or store:</p>
            <ul>
               <li>Personal information</li>
               <li>IP addresses</li>
               <li>Browser data</li>
               <li>Device information</li>
               <li>Cookies</li>
               <li>User behavior or patterns</li>
               <li>Location data</li>
               <li>Any user-identifying information</li>
            </ul>

            <h3>2.3 How We Use Anonymous Visit Data</h3>
            <p>We use anonymous visit counts solely for:</p>
            <ul>
               <li>Understanding overall website traffic</li>
               <li>Monitoring site performance</li>
               <li>Statistical purposes</li>
            </ul>

            <h3>2.4 Website Usage</h3>
            <p>Our website is purely informational. We:</p>
            <ul>
               <li>Do not require user accounts</li>
               <li>Do not ask for any personal information</li>
               <li>Do not track individual visitor behavior</li>
               <li>Do not use cookies</li>
               <li>Do not collect any information through forms</li>
               <li>Do not have any wallet connection functionality</li>
            </ul>

            <h3>2.5 Third-Party Services</h3>
            <p>Our website is hosted by Digital Ocean. The anonymous visit counter is provided by Digital Ocean. These services may maintain standard server logs required for website operation, but we do not access or use this information for tracking purposes.</p>

            <h2 className='header2'>3. Links to Other Websites</h2>
            <p>Our website may contain links to external websites. This privacy policy applies only to our website. We are not responsible for the privacy practices or content of external sites. We encourage users to read the privacy policies of any external sites they visit.</p>

            <h2 className='header2'>4. Changes to This Privacy Policy</h2>
            <p>We may update this privacy policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page.</p>

            <h2 className='header2'>5. Contact Us</h2>
            <p>If you have questions about this privacy policy, you can contact us at:</p>
            <p><strong>BONK Inu</strong><br />
               core@bonkcoin.com</p>

            <h2 className='header2'>6. Legal Compliance</h2>

            <h3>6.1 California Residents (CCPA/CPRA)</h3>
            <p>Since we only collect anonymous aggregate data and no personal information:</p>
            <ul>
               <li>We do not sell personal information</li>
               <li>We do not share personal information</li>
               <li>We do not collect personal information to track or profile visitors</li>
               <li>The anonymous visit counts cannot be used to identify individuals</li>
            </ul>

            <h3>6.2 European Union Residents (GDPR)</h3>
            <p>Regarding our anonymous visit counting:</p>
            <ul>
               <li>We do not process personal data as defined by GDPR</li>
               <li>Anonymous aggregate statistics cannot be used to identify individuals</li>
               <li>We do not transfer personal data</li>
               <li>We do not require cookie consent as we don't use cookies</li>
               <li>We maintain transparency about our limited data collection</li>
            </ul>

            <h2 className='header2'>7. Security</h2>
            <p>While we only collect anonymous visit counts, we maintain standard security measures to protect our website from unauthorized access, alteration, or destruction.</p>

            <h2 className='header2'>8. Data Retention</h2>
            <p>Anonymous visit counts are retained for [time period] and are used solely for statistical purposes. This data cannot be used to identify any individual visitor.</p>

         </div>
      </section>
   );
}

export default Privacy;
