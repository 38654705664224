import { useEffect, useRef } from "react";
import './Bandit.css';
import React from "react";

export function Bandit() {
    const banditInitialized = useRef(false);
    useEffect(() => {
      if (!banditInitialized.current) {
        // This is to integrate "Campaign List"
        const initializeCampaignListBandit = () => {
          if ((window as any)?.Bandit) {
            const bandit = new (window as any).Bandit.init({
              apiKey: "95a7300cd4e34ff7890caeece5e32887",
              cluster: "mainnet",
            });
            bandit.mountCampaignList(
              {
                showFilter: false,
              },
              "bandit-campaign-list"
            );
          } else {
            console.error("Bandit library failed to load.");
          }
        };
  
        // This is to integrate "Pro Kit"
        const initializeProKitListBandit = () => {
          if ((window as any)?.Bandit) {
            const bandit = new (window as any).Bandit.init({
              apiKey: "95a7300cd4e34ff7890caeece5e32887",
              cluster: "mainnet",
            });
            bandit.mountAggregator(
              {
                showFilter: false,
              },
              "bandit-pro-kit"
            );
          } else {
            console.error("Bandit library failed to load.");
          }
        };
  
        // initializeCampaignListBandit();
        // initializeProKitListBandit();
  
        banditInitialized.current = true;
      }
    }, []);
  
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: "8px",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "white",
            margin: "0",
          }}
        >
          Quests
        </p>
        <p
          style={{
            marginTop: "0px",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            opacity: 0.8,
            width: "240px",
          }}
        >
          Earn $BONK rewards by participating in Quests.
        </p>
      </div>
      <div id="bandit-campaign-list"></div>
    </div>
    <div
      style={{
        width: "100%",
        borderBottom: "1px solid gray",
        margin: "20px 0",
        opacity: 0.2,
      }}
    />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginBottom: "8px",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "white",
            margin: "0",
          }}
        >
          Aggregated Mints
        </p>
        <p
          style={{
            marginTop: "0px",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
            opacity: 0.8,
            width: "220px",
          }}
        >
          Mint unique NFTs with your $BONK tokens.
        </p>
      </div>
      <div id="bandit-pro-kit"></div>
    </div>
  </>
  );
}