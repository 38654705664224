import React from 'react';
import './Card.css';
import { Link } from 'react-router-dom';

export function Card(
   props: Readonly<{
      className?: string;
      title?: string;
      description?: string;
      children?: React.ReactNode;
   }>
) {
   return (
      <div className={`${props.className ?? ''} card ${props.title || props.description ? 'flex column gap' : ''} `}>
         <div className="flex column gap-small">
            {props.title && <h2>{props.title}</h2>}
            {props.description && (
               <h3 dangerouslySetInnerHTML={{ __html: props.description.replace(/<br\s*\/?>/g, '<br/>') }} /> //if <br/> -> break col
            )}
         </div>
         {props.children !== undefined && props.children}
      </div>
   );
}
