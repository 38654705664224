import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { DataProvider } from './contexts/data.context';
import ReactGA from 'react-ga4';
import './static/style/global.css';
import './static/style/text.css';
import './static/style/layout.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
ReactGA.initialize('G-DXYS2ZHJKZ');

root.render(
   <DataProvider>
      <App />
   </DataProvider>
);
reportWebVitals();
