import { Bandit } from '../components/Bandit';
import Nft from '../components/NFT';
import './NFT.css';

function NFT() {
   return (
      <section>
         <div className="container">
            {/* <Nft /> */}
            <Bandit />
         </div>
      </section>
   );
}

export default NFT;
