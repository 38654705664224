import { useRef, useEffect, useState, useMemo } from 'react';
import './Carousel.css';
import { CarouselPartner } from '../models/carousel-partner.model';
import { Link } from 'react-router-dom';
import community from '../static/data/community.json';

const curveFactor = 44.65;
const rotationFactor = 50;

export function CarouselLogos() {
   const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

   const doubledPartners = useMemo(() => {
      if (!community) return [];
      const partners: CarouselPartner[] = community;

      // const randomPartners = partners.sort(() => Math.random() - 0.5);
      const result: CarouselPartner[] = [];
      for (let i = 0; i < 10; i++) {
         result.push(...partners.map((partner) => ({ ...partner, id: partner.name + i * partners.length })));
      }
      return result;
   }, []);

   const sliderContainerRef = useRef(null);

   useEffect(() => {
      const container = sliderContainerRef.current as any;
      const partners = container.querySelectorAll('.partner');

      let intervalId: any;

      const updateTranslateY = () => {
         const centerX = container.offsetWidth / 2;
         const [a, b] = getTranslateArgs();

         partners.forEach((partner: any, index: any) => {
            if (isElementInViewport(partner)) {
               const rect = partner.getBoundingClientRect();
               const x = rect.left + rect.width / 2;
               const distanceFromCenter = Math.abs(centerX - x);
               const translateY = a * Math.pow(distanceFromCenter, 2) + b;

               const rf = rotationFactor / -screenSize.width;
               const rotation = (centerX - x) * rf;
               partner.style.transform = `translateY(${translateY}px) rotate(${rotation}deg)`;
            } else {
            }
         });
      };
      const updateScreenSize = () => {
         setScreenSize({ width: window.innerWidth, height: window.innerHeight });
      };

      updateTranslateY();
      intervalId = setInterval(updateTranslateY, 20);

      window.addEventListener('resize', updateScreenSize);

      return () => {
         clearInterval(intervalId);
         window.removeEventListener('resize', updateScreenSize);
      };
   }, [screenSize]);

   function isElementInViewport(el: any) {
      const rect = el.getBoundingClientRect();
      return rect.top >= -100 && rect.left >= -500 && rect.bottom - 500 <= (window.innerHeight || document.documentElement.clientHeight) && rect.right - 500 <= (window.innerWidth || document.documentElement.clientWidth);
   }

   function getTranslateArgs(): [number, number] {
      //the factor calc will be fixed if earth-image size stays fixed (zoom-out)
      if (screenSize.width >= 1920) {
         return [curveFactor / 100 / 1920, 0];
      }
      return [curveFactor / 100 / screenSize.width, 0];
   }

   return (
      <div className="carousel">
         <div ref={sliderContainerRef} className="sliding-banner">
            <div className={`partner-container ${screenSize.width < 1000 ? 'faster' : ''}`}>
               {doubledPartners.length > 0 &&
                  doubledPartners.map((partner: CarouselPartner) => (
                     <Link to={partner.url ?? '#'} target="_blank" key={partner.id} className="partner">
                        <div className="partner-content">
                           <div className="carousel-box">
                              <img className="test" src={partner.image} alt={partner.name} />
                              {/*
                              <div>
                                 <img src={arrow} alt="arrow" />
                                 <div>
                                    <p>{partner.name}</p>
                                 </div>
                              </div>
                            */}
                           </div>
                        </div>
                     </Link>
                  ))}
            </div>
         </div>
      </div>
   );
}
