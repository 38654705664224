import "../static/style/loading.css"

export function Loading() {
    return (
        <div className="lds-container">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
