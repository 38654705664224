import React, { useEffect, useState } from "react";
import {
  CategoryApiResponseModel as IntegrationsApiResponseModel,
  CategoryDataModel,
} from "../models/category-api-response.model";
import { fetchBonkDataIntegrations } from "../services/strapi.service";
import IntegrationsJson from "../static//data/integrations.json";

type DataProviderProps = {
  categories: CategoryDataModel[] | undefined;
  integrationsCount: number;
};

export const DataContext = React.createContext({} as DataProviderProps);

export const DataProvider = ({ children }: any) => {
  const [categories, setCategories] = useState<CategoryDataModel[]>();
  const [integrationsCount, setIntegrationsCount] = useState<number>(0);

  async function fetchData() {
    let data: IntegrationsApiResponseModel | undefined = await fetchBonkDataIntegrations();
    // data = undefined;
    if (!data) {
      data = IntegrationsJson as IntegrationsApiResponseModel;
    }

    setCategories(
      data.data.sort((a, b) => a.attributes.sort - b.attributes.sort)
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (categories) {
      setIntegrationsCount(
        categories
          .map(
            (category) =>
              category.attributes.integrations.data.filter(
                (o) => o.attributes.active === true
              ).length
          )
          .reduce((a, b) => a + b)
      );
    }
  }, [categories]);

  return (
    <DataContext.Provider
      value={{ categories: categories, integrationsCount: integrationsCount }}
    >
      {children}
    </DataContext.Provider>
  );
};
